import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import hospital from '../../Images/hhospital1 (1).jpg'
import hospital2 from '../../Images/hhospital1 (2).jpg'
import hospital3 from '../../Images/hhospital1 (3).jpg'
import hospital4 from '../../Images/hhospital1 (4).jpg'
import hospital5 from '../../Images/hhospital1 (5).jpg'
import hospital6 from '../../Images/hhospital1 (6).jpg'


export default function MuscularDystrophy() {
  return (
    <>
    <div className="container my-5 py-5 ">
      <div className="row align-items-center">
        <div className="col-md-6">
            <h3 className={`${styles.color} my-3`}>Muscular Dystrophy Hospital ​</h3>
            <p> Project description: Hospital Building  </p>
            <p>Type of contract: Design and supervision </p>
            <p>Type of works : Architectural , structure and electromechanical design </p>
        </div>
        <div className="col-md-6">
            <img src={hospital} alt="achegy" className='img-fluid'/>
        </div>   
      </div>
      <div className="row my-5 pb-5">
     
     <div className="col-md-4 my-2 text-center">
        <img src={hospital2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={hospital3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={hospital4} alt="achegy" className={`${styles.imgSizeProj}`}/>  
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={hospital5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={hospital6} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>

 
   
   </div>
    </div>

</>
  )
}
