import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import godness from '../../Images/godness1.jpg'
import godness2 from '../../Images/godness2.jpg'
import godness3 from '../../Images/godness3.jpg'
import godness4 from '../../Images/godness4.jpg'
import godness5 from '../../Images/godness5.jpg'



export default function GodnessHospital() {
  return (
   
    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> Bawbtal lelkher Hospital​</h3>
                <p> Project description: Hospital Building  </p>
                <p>Type of contract: Design and supervision </p>
                <p>Type of works : Architectural , structure and electromechanical design </p>
            </div>
            <div className="col-md-6">
                <img src={godness} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={godness2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={godness3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={godness4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={godness5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>      
          </div>

        </div>

    </>
  )
}
