import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import hospital5p1 from '../../Images/hospital5.1.png'
import hospital5p2 from '../../Images/hospital5.2.png'
import hospital5p3 from '../../Images/hospital5.3.png'
import azema from '../../Images/azema1.jpg'
import azema2 from '../../Images/azema2.jpg'
import azema3 from '../../Images/azema3.jpg'
import azema4 from '../../Images/azema4.jpg'
import azema5 from '../../Images/azema5.jpg'
import azema6 from '../../Images/azema6.jpg'
import azema7 from '../../Images/azema7.jpg'







export default function Hospital5() {
  return (

    <>
        <div className="container my-5 py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6">
                <h3 className={`${styles.color} my-3`}> AL Azema Hospital ​</h3>
                <p> Project description: Hospital Building  </p>
                <p>Type of contract: Design </p>
                <p> Area:  2145 m2  </p>
                <p>Type of works : Architectural coordination's and Electromechanical </p>
            </div>
            <div className="col-md-6">
                <img src={azema} alt="achegy" className='img-fluid'/>
            </div>   
          </div>
          <div className="row my-5 pb-5">
          <div className="col-md-4 my-1 text-center">
               <img src={azema2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={azema} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={azema3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={azema4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={azema5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={azema6} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-1 text-center">
               <img src={azema7} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
          <div className="col-md-4 my-1 text-center">
               <img src={hospital5p1} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital5p2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
            <div className="col-md-4 my-2 text-center">
               <img src={hospital5p3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
            </div>
      
        
          
          </div>

        </div>

    </>
  )
}
