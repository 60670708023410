import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import dental from '../../Images/dental1.jpg'
import dental2 from '../../Images/dental2.jpg'
import dental3 from '../../Images/dental3.jpg'
import dental4 from '../../Images/dental4.jpg'
import dental5 from '../../Images/dental5.jpg'


export default function DentalMedicalCenter() {
  return (
    <>
    <div className="container my-5 py-5 ">
      <div className="row align-items-center">
        <div className="col-md-6">
            <h3 className={`${styles.color} my-3`}>Dental Medical Center ​</h3>
            <p> Project description: Medical Center  </p>
            <p>Type of contract: Design </p>
            <p>Type of works : Architectural Design </p>
        </div>
        <div className="col-md-6">
            <img src={dental} alt="achegy" className='img-fluid'/>
        </div>   
      </div>
      <div className="row my-5 pb-5">
     
     <div className="col-md-4 my-2 text-center">
        <img src={dental2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={dental3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={dental4} alt="achegy" className={`${styles.imgSizeProj}`}/>  
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={dental5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>

 
   
   </div>
    </div>

</>
  )
}
