import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import smart from '../../Images/smart1.jpg'
import smart2 from '../../Images/smart2.jpg'


export default function SmartCareHospital() {
  return (
    <>
    <div className="container my-5 py-5 ">
      <div className="row align-items-center">
        <div className="col-md-6">
            <h3 className={`${styles.color} my-3`}>Smart Care Hospital in Mokattam ​</h3>
            <p> Project description: Hospital Building  </p>
            <p>Type of contract: Design </p>
            <p>Type of works : Architectural And structure design </p>
        </div>
        <div className="col-md-6">
            <img src={smart} alt="achegy" className='img-fluid'/>
        </div>   
      </div>
      <div className="row my-5 pb-5">
     
     <div className="col-md-4 my-2 text-center">
        <img src={smart} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={smart2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
   
   </div>
    </div>

</>
  )
}
