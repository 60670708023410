import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import kayan from '../../Images/kayan1.jpg'
import kayan2 from '../../Images/kayan2.jpg'
import kayan3 from '../../Images/kayan3.jpg'
import kayan4 from '../../Images/kayan4.jpg'
import kayan5 from '../../Images/kayan5.jpg'
import kayan6 from '../../Images/kayan6.jpg'
import kayan7 from '../../Images/kayan7.jpg'



export default function Kayan() {
  return (
    <>
    <div className="container my-5 py-5 ">
      <div className="row align-items-center">
        <div className="col-md-6">
            <h3 className={`${styles.color} my-3`}>Kayan Hospital ​</h3>
            <p> Project description: Hospital Building  </p>
            <p>Type of contract: Design</p>
            <p>Type of works : Architectural , structure and electromechanical design </p>
        </div>
        <div className="col-md-6">
            <img src={kayan} alt="achegy" className='img-fluid'/>
        </div>   
      </div>
      <div className="row my-5 pb-5">
     
     <div className="col-md-4 my-2 text-center">
        <img src={kayan2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={kayan3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={kayan4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={kayan5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={kayan6} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={kayan7} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>



 
   
   </div>
    </div>

</>
  )
}
