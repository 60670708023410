import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import sa from '../../Images/sa1.jpg'
import sa2 from '../../Images/sa2.jpg'
import sa3 from '../../Images/sa3.jpg'
import sa4 from '../../Images/sa4.jpg'
import sa5 from '../../Images/sa5.jpg'

export default function Sahrgt() {
  return (
    <>
    <div className="container my-5 py-5 ">
      <div className="row align-items-center">
        <div className="col-md-6">
            <h3 className={`${styles.color} my-3`}>Sahrgat Hospital ​</h3>
            <p> Project description: Hospital Building  </p>
            <p>Type of contract: Design </p>
            <p>Type of works : Architectural And structure design </p>
        </div>
        <div className="col-md-6">
            <img src={sa} alt="achegy" className='img-fluid'/>
        </div>   
      </div>
      <div className="row my-5 pb-5">
     
     <div className="col-md-4 my-2 text-center">
        <img src={sa2} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={sa3} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={sa4} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>
     <div className="col-md-4 my-2 text-center">
        <img src={sa5} alt="achegy" className={`${styles.imgSizeProj}`}/> 
     </div>

 
   
   </div>
    </div>

</>
  )
}
